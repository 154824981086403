import React from "react";
import axios from 'axios';
import {apiUrl} from './../Config/ApiSettings';

class ProgramSingle extends React.Component {

  // State will apply to the posts object which is set to loading by default
  state = {
    programs: [],
    isLoading: true,
    errors: null
  };


  // Now we're going to make a request for data using axios
  getPosts() {
      // let programName = this.props.location;
      let pathString = window.location.pathname;
      pathString = pathString[0] === '/' ? pathString.substring(1) : pathString;
      pathString = (pathString[pathString.length - 1] === '/') ? pathString.slice(0, -1) : pathString;
    // let lastChar = programName.substr(-1); // Selects the last character
    // if (lastChar !== '/') {         // If the last character is not a slash
    //    programName = programName + '/';            // Append a slash to it.
    // }
    let programName = pathString;

    axios
      // This is where the data is hosted
      .get(`${apiUrl}/${programName}/?api=true`, {
    headers: {
        // 'Content-Type': 'application/json'
    },

    })
      // Once we get a response and store data, let's change the loading state
      .then(response => {
        this.setState({
          programs: response.data,
          isLoading: false
        });

      })
      // If we catch any errors connecting, let's update accordingly
            .catch(error => {
                console.log(error);
                    window.location.href = '/not-found';

                this.setState({ error, isLoading: false } )
            });
  }
  // Let's our app know we're ready to render the data
  componentDidMount() {

    this.getPosts();
  }


componentDidUpdate(prevProps, prevState) {
  // only update chart if the data has changed
  if (prevProps.path !== this.props.path) {
            this.setState({
                isLoading: true,
            });
            this.getPosts();
  }
}


  // Putting that data to use
  render() {

    const { isLoading, programs } = this.state;
     return (
      <React.Fragment>
          {!isLoading ? (
            programs.map(program => {

              const { id, title, program_image, program_full_description } = program;
              document.title = title + " - Radio Kampus 97,1FM #SAMESZTOSY";
              return (
                <div key={id} className="program-single-container">
                  <div className="program-image">
                    <img src={program_image} alt="" />
                  </div>
                  <div className="program-description">
                    <h2>{title}</h2>
                    <div dangerouslySetInnerHTML={ { __html: program_full_description }} />
                  </div>
                </div>
              );
            })
          ) : (
            <div className="loading-container"><p>Wczytywanie...</p></div>
          )}
      </React.Fragment>
    );
  }
}

export default ProgramSingle;
import React from "react";
// import React, { Suspense, lazy } from 'react';

import PodcastListHome from './../Components/PodcastListHome';
import NewsListHome from './../Components/NewsListHome';

import Partners from './../Components/Partners';
import HomeSlider from './../Components/HomeSlider';
// const NewsListHome = React.lazy(() => import('./../Components/NewsListHome'));

class Home extends React.Component {

  	componentDidMount() {

          let scrollPos = 0;
          const nav = document.querySelector('.podcasts-section .dynamic-boxes');
          const nav2 = document.querySelector('.news-section .dynamic-boxes');
          const nav3 = document.querySelector('.cathedras-section .dynamic-boxes');
          document.title = "Radio Kampus 97,1 FM #SAMESZTOSY";

          function checkPosition() {
            let windowY = window.scrollY;
            if (windowY < scrollPos) {
              // Scrolling UP
              nav.classList.add('is-visible');
              nav2.classList.add('is-visible');
              nav3.classList.add('is-visible');
              nav.classList.remove('is-hidden');
              nav2.classList.remove('is-hidden');
              nav3.classList.remove('is-hidden');
            } else {
              // Scrolling DOWN
              nav.classList.add('is-visible');
              nav2.classList.add('is-visible');
              nav3.classList.add('is-visible');
              nav.classList.remove('is-hidden');
              nav2.classList.remove('is-hidden');
              nav3.classList.remove('is-hidden');
            }
            scrollPos = windowY;
          }



          function debounce(func, wait = 10, immediate = true) {
            let timeout;
            return function() {
              let context = this, args = arguments;
              let later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
              };
              let callNow = immediate && !timeout;
              clearTimeout(timeout);
              timeout = setTimeout(later, wait);
              if (callNow) func.apply(context, args);
            };
          };

          window.addEventListener('scroll', debounce(checkPosition))




          var isScrolling;

        // Listen for scroll events
        window.addEventListener('scroll', function ( event ) {
        	// var startScrolling = setTimeout(function() {
        		// Run the callback
        		// console.log( 'Scrolling has started.' );
        	// }, 1000);

        	// Clear our timeout throughout the scroll
        	window.clearTimeout( isScrolling );

        	// Set a timeout to run after scrolling ends
        	isScrolling = setTimeout(function() {
        		nav.classList.remove('is-visible');
        		nav2.classList.remove('is-visible');
        		nav3.classList.remove('is-visible');
        		// Run the callback
        		// console.log( 'Scrolling has stopped.' );

        	}, 66);

        }, false);



        let headerNotHome = document.querySelector(".header-container.home");
        let homeContent = document.querySelector(".header-container.home + .heading-player-area + .main-content-container .home-wrap");
        // VARIABLE FOR DETECTION HEIGHT
        // let headerPlayer = document.querySelector(".heading-player-area");
        let headerInitialOffset;
        if(! (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))  ) {
        if (headerNotHome) {



          headerInitialOffset = 1312;

          if ((window.innerWidth < 1160) && (window.innerWidth > 767)) {
            headerInitialOffset = 1080;
          }


          if (homeContent) {homeContent.style.marginTop = headerInitialOffset + 'px';}
        } else {
          if (homeContent) {homeContent.style.marginTop = null; }
        }

        // headerInitialOffset DETECTION, WORKS BAD IF PAGE IS BELOW THE POINT 

        // setTimeout(function() {
        //   headerInitialOffset = headerPlayer.offsetHeight + 40;
        //   homeContent.style.marginTop = headerInitialOffset + 'px';
        //   }, 1000);
        // 


      }



        window.onscroll = function() {scrollFunction(); homeMenuStick(headerInitialOffset)};

        function scrollFunction() {



        let headerNotHome = document.querySelector(".header-container:not(.home)");


          if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            	if (headerNotHome) {
            		headerNotHome.classList.add('smaller');
        		}
          } else {
            	if (headerNotHome) {
            		headerNotHome.classList.remove('smaller');
        		}
          }
        }

        function homeMenuStick(headerInitialOffset) {

            if( (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))  ) {

                const storeScroll = () => {
                    if (document.body.scrollTop > 375 || document.documentElement.scrollTop > 375) {
                        
                        document.querySelector(".main-container").style.paddingTop = '0px';
                      	if (headerNotHome) {
                        		headerNotHome.classList.add('sticked');
                        		headerNotHome.classList.add('smaller');
                  		  }
                        // console.log("TAK");
                    } else {
                      	if (headerNotHome) {
                        		headerNotHome.classList.remove('sticked');
                        		headerNotHome.classList.remove('smaller');
                  		  }
                        document.querySelector(".main-container").style.paddingTop = '0px';
                    }
                }

                // Listen for new scroll events, here we debounce our `storeScroll` function
                document.addEventListener('scroll', debounce(storeScroll, 20000), { passive: true });

                // Update scroll position for first time
                storeScroll();

            } else {

                const storeScroll = () => {
                var elementTarget = document.querySelector(".news-slider");

                    if (elementTarget) {
                        // console.log(elementTarget.offsetTop);
                        if (document.body.scrollTop > elementTarget.offsetTop - 150 || document.documentElement.scrollTop > elementTarget.offsetTop - 150) {
                            if (headerNotHome) {
                                headerNotHome.classList.add('sticked');
                                // console.log("Scroll point passed");
                            }
                        } else {
                            if (headerNotHome) {
                                headerNotHome.classList.remove('sticked');
                            }
                        }
                    }
                }

                // Listen for new scroll events, here we debounce our `storeScroll` function
                document.addEventListener('scroll', debounce(storeScroll, 20000), { passive: true });

                // Update scroll position for first time
                storeScroll();

                // ALTERNATIVE: Mutation Observer implementation - check REACT dedicated component as alternative

                // if (
                //   "IntersectionObserver" in window &&
                //   "IntersectionObserverEntry" in window &&
                //   "intersectionRatio" in window.IntersectionObserverEntry.prototype
                // ) {
                // let observer = new IntersectionObserver(entries => {
                //  // console.log(entries[0].boundingClientRect.y);
                //   if (entries[0].boundingClientRect.y < 160) {

                //     headerNotHome.classList.add('sticked');
                //   } else {
                //     headerNotHome.classList.remove('sticked');
                //   }
                // });
                // observer.observe(document.querySelector(".news-slider"));
                // }

            }
        }

    }

    render() {
        return (
            <div className="home-wrap" onScroll={this.handleScroll}>

 
              <HomeSlider />

              <NewsListHome />

          {/*      <Suspense fallback={<div>Wczytywanie...</div>}>
                  <NewsListHome />
                </Suspense>   */}   					

      				<PodcastListHome />   
 
              <Partners />




            </div>
        )
    }
}

export default Home;
import React from "react";
import RadioPlayButton from './../Assets/img/play_small.svg';
import RadioPauseButton from './../Assets/img/pause_small.svg';
import RadioPlayButtonHome from './../Assets/img/play.svg';
import RadioPauseButtonHome from './../Assets/img/pause.svg';

class AudioPlayButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isPlayed: this.props.state.isPlayed, path: this.props.path};
    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    var myAudio = document.querySelector(".react-audio-player");
    if (this.state.isPlayed) {
      myAudio.pause()
    } else {
      myAudio.load();
      myAudio.play()
    }
    this.setState(prevState => ({
      isPlayed: !prevState.isPlayed
    }));
  }

//     componentDidMount() {
//     var myAudio = document.querySelector(".react-audio-player");
//       myAudio.load();
//       myAudio.play();
//       myAudio.muted = true;
//     setTimeout(() => {
//             myAudio.muted = false;
//     }, 7000)
// }


  render() {



var myAudio = document.querySelector(".react-audio-player");
var volumeDrag = false;

var volume = document.querySelector('.volume,.volumeBar');
if (volume !== null) {
volume.addEventListener("mousedown", (e) => { 
    volumeDrag = true;
    myAudio.muted = false;
    //   document.querySelector('.sound').classList.remove('muted');
    updateVolume(e.pageX);
});
}
document.addEventListener('mouseup', (e) => { 
    if (volumeDrag) {
        volumeDrag = false;
        updateVolume(e.pageX);
    }
});
document.addEventListener('mousemove', (e) => { 
    if (volumeDrag) {
        updateVolume(e.pageX);
    }
});
var updateVolume = function (x, vol) {
    var volume = document.querySelector('.volume');
    var percentage;
    //if only volume have specificed
    //then direct update volume
    if (vol) {
        percentage = vol * 100;
    } else {
        var rect = volume.getBoundingClientRect();
        var position = x - rect.left + document.body.scrollLeft;

// var rect = el.getBoundingClientRect();

// {
//   top: rect.top + document.body.scrollTop,
//   left: rect.left + document.body.scrollLeft
// }

        percentage = 100 * position / (parseFloat(getComputedStyle(volume, null).width.replace("px", "")));

    }

    if (percentage > 100) {
        percentage = 100;
    }
    if (percentage < 0) {
        percentage = 0;
    }

    //update volume bar and video volume
    var volume_bar = document.querySelector('.volumeBar');
    volume.style.clip = 'rect(0px, '+percentage+'px, 50px, 0px)';

    volume_bar.style.width = percentage + '%';
    myAudio.volume = percentage / 100;

};





      const isPlayed = this.state.isPlayed
      let playButton;
      let isHome = this.props.path;
    if(isPlayed) {
      playButton = <span><img className="normal-audio-button" src={isHome ? RadioPauseButtonHome : RadioPauseButton} alt="Pause" /><img className="home-sticky-audio-button" src={RadioPauseButton} alt="Pause" /></span>
    } else {
      playButton = <span><img className="normal-audio-button" src={isHome ? RadioPlayButtonHome : RadioPlayButton} alt="Play" /><img className="home-sticky-audio-button" src={RadioPlayButton} alt="Pause" /></span>
    }


    return (
      <div className="player-container">
          <div className="player-wrapper">
            <button className={"main-player-button " + (isHome ? 'home' : '')} onClick={this.handleClick}>
              {playButton}
            </button>
            <div className={"volume-container" + ((isPlayed) ? (" visible") : (" hidden"))}>
              <div className="volume-background" />
              <div className="volume-background-min" />
                <div className="volume-bar-container">
                  <div className="volumeBar"><div className="volumebkg"></div><div className="volume"></div></div>
                <div className="volume-icon" />
              </div>
            </div>
          </div>
      </div>
    );
  }
}

export default AudioPlayButton;
import React from "react";
import {
  Link,
} from "react-router-dom";
import axios from 'axios';
import {apiUrl} from './../Config/ApiSettings';

class SearchListElement extends React.Component {
  // State will apply to the posts object which is set to loading by default
  state = {
    results: [],
    isLoading: true,
    errors: null
  };
  // Now we're going to make a request for data using axios
  getPosts() {

    let search_query = this.props.path;

 	
    axios
      // This is where the data is hosted
      .get(`${apiUrl}/search/?q=${search_query}&api=true`, {
    headers: {
        // 'Content-Type': 'application/json'
    },

    }) 
      // Once we get a response and store data, let's change the loading state
      .then(response => {
        this.setState({
          results: response.data,
          isLoading: false
        });
                      
      })
      // If we catch any errors connecting, let's update accordingly
      .catch(error => this.setState({ error, isLoading: false }));
  }
  // Let's our app know we're ready to render the data
  componentDidMount() {
    this.getPosts();
  }


componentDidUpdate(prevProps, prevState) {
  // only update chart if the data has changed
  console.log(this.props.path);
  if (prevProps.path !== this.props.path) {
            this.getPosts()
  }
}




  // Putting that data to use
  render() {
    const { isLoading, results } = this.state;

    return (
      <React.Fragment>

          <div className="results-list">
          {!isLoading ? (
            (results !== '') ? ( results.map(result => {
                  // news_date_added
                const { id, title, url, description } = result;
                let description_clean = description.replace(/\u00a0/g, " ");
              return (
                  <div className="results-item" key={id}>
                    <h3 className="results--title"><Link to={url}>{title}</Link></h3>
                        <div className="results--description">
                          <p>{description_clean}</p>
                        </div>
                  </div>
              );
            }) ) : (<p>Nie znaleziono stron dla podanej frazy.</p>)
          ) : (
            <p>Wczytywanie...</p>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default SearchListElement;
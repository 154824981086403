import React from "react";
import {
  useParams
} from "react-router-dom";
import SearchListElement from './../Components/SearchListElement';

const SearchPage = ({ match, location }) => {
	let { searchQuery } = useParams();
//	console.log(location);
document.title = "Wyszukiwanie - " + searchQuery + " - Radio Kampus 97,1FM #SAMESZTOSY"; 
  return (
    <div className="results-section">
      <h2><span className="results-intro-text">Wyniki wyszukiwania dla: </span>
      {searchQuery}</h2>
             
          <div className="news-list-container">
				<SearchListElement path={ searchQuery } />
    	</div>
    </div>
  );
}

export default SearchPage;
import React from 'react';
import { bool } from 'prop-types';
import {
  NavLink,
} from "react-router-dom";
import SearchBar from './SearchBar';
import MenuTopOutside from './MenuTopOutside';
import MenuTopInside from './MenuTopInside';
import MenuContactData from './MenuContactData';
import SocialMenu from './SocialMenu';
import {
  BrowserView,
  MobileView,
  isMobile
} from "react-device-detect";


const Menu = ({ open, setOpen, ...props }) => {


    if (navigator.appVersion.indexOf("Win")!==-1) {
      const htmlTag = document.querySelector('html');
      htmlTag.classList.add('is-win');

    }


    function blockBody() {
        const bodyLocker = document.querySelector('body,html');
        bodyLocker.classList.toggle('locked');
        console.log('Blokada body');
    }

    return (

    <nav className={open ? 'menu-container top-menu open' : 'menu-container top-menu closed'}>
      <div className="top-menu--dropdown">
        <nav className={open ? 'main-page-menu open' : 'main-page-menu closed'}>
          <div className={"main-page-menu-column" + ((isMobile) ? " mobile-wrap" : "") }>
            <MobileView viewClassName='mobile-items-wrapper'>
                <div className="menu-mobile-items"><div className="search-bar" ><SearchBar open={!open} setOpen={setOpen} /></div>
                <SocialMenu />
                 </div>
            </MobileView>
            <MenuContactData />

            <div className="menu-column--navigation">
                  <MenuTopInside open={!open} setOpen={setOpen} />
            </div>
          </div>
        </nav>
      </div>  

      <div className="top-menu--regular">

          <NavLink to="/"><span className="radio-freq" onClick={open ? (() => { setOpen(!open); blockBody()}) : () => false} {...props}>97,1 FM</span></NavLink>

          <MenuTopOutside open={!open} setOpen={setOpen} />

          <BrowserView>
              <div className="menu-group-right"><div className="search-bar"><SearchBar open={open} setOpen={setOpen} /></div>
              <SocialMenu />
               </div>
          </BrowserView>
      </div>
    </nav>





    )
}

Menu.propTypes = {
  open: bool.isRequired,
}


export default Menu;


import React from "react";
import axios from 'axios';
import {apiUrl} from './../Config/ApiSettings';


class WeekPlaylistInfo extends React.Component {
  // State will apply to the posts object which is set to loading by default
  state = {
    programsinfo: null,
    isLoading: true,
    errors: null,
  };





  // Now we're going to make a request for data using axios
  getPosts() {


    let schedule_query_done = this.props.path;


    if (!schedule_query_done) {
        let schedule_query = new Date().toLocaleDateString('pl-PL', {
            weekday: 'long'
        })
        schedule_query_done = schedule_query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\u0142/g, "l");
    }

// const start = 1 * 60 + 5;
//  const end =  2 * 60 + 57;
//  const date = new Date(); 
//  const now = date.getHours() * 60 + date.getMinutes();

//  if(start <= now && now <= end)
//    console.log("in time"); else {
//        console.log("not in time"); 
//   


      // console.log("NIE");

      let program_category_query = schedule_query_done;
    

  
    axios
      // This is where the data is hosted
      .get(`${apiUrl}/ramowka/${program_category_query}/?api=true&info=true`, {
}) 
      // Once we get a response and store data, let's change the loading state
      .then(response => {
        this.setState({
          programsinfo: response.data[0],
          isLoading: false
        });
                      
      })
      // If we catch any errors connecting, let's update accordingly
      .catch(error => this.setState({ error, isLoading: false }));
  }
  // Let's our app know we're ready to render the data
  componentDidMount() {
    this.getPosts();

  }


componentDidUpdate(prevProps, prevState) {
  // only update chart if the data has changed
  if (prevProps.path !== this.props.path) {
    this.getPosts();

  }
}




  // Putting that data to use
  render() {
    const { isLoading, programsinfo } = this.state;

        // console.log(programsinfo);

    return (
      <React.Fragment>
        <div className="programs-info-container">

    {!isLoading ? (
        <div className="programs-info-row">
          <div className="programs-info-column" dangerouslySetInnerHTML={ { __html: programsinfo.broadcast_day_info_1 }} />
          <div className="programs-info-column" dangerouslySetInnerHTML={ { __html: programsinfo.broadcast_day_info_2 }} />
          <div className="programs-info-column" dangerouslySetInnerHTML={ { __html: programsinfo.broadcast_day_info_3 }} />
        </div>
 
   
          ) : (
            <p>Wczytywanie...</p>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default WeekPlaylistInfo;
import React from "react";
import Breadcrumbs from '.././Components/Breadcrumbs';
import MenuContactData from './../Components/MenuContactData';

function ContactPage() {
  return (
    <div className="contact-section">
          <Breadcrumbs />    
		<MenuContactData />
    </div>
  );
}

export default ContactPage;
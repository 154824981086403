import React from "react";
import {
  Link
} from "react-router-dom";
import axios from 'axios';
import {apiUrl} from './../Config/ApiSettings';
import parse, { domToReact } from 'html-react-parser';
import ModalElement from '.././Components/ModalElement';
import TweetEmbed from 'react-tweet-embed';
import { InstagramEmbed, TikTokEmbed } from 'react-social-media-embed';


class NewsSingle extends React.Component {

  // State will apply to the posts object which is set to loading by default
  state = {
    newslist: [],
    isLoading: true,
    errors: null
  };


  // Now we're going to make a request for data using axios
  getPosts() {
      // let newsName = this.props.location;
      let pathString = window.location.pathname;
      pathString = pathString[0] === '/' ? pathString.substring(1) : pathString;
      pathString = (pathString[pathString.length - 1] === '/') ? pathString.slice(0, -1) : pathString;
    // let lastChar = programName.substr(-1); // Selects the last character
    // if (lastChar !== '/') {         // If the last character is not a slash
    //    programName = programName + '/';            // Append a slash to it.
    // }
    let newsName = pathString;


    axios
      // This is where the data is hosted
      .get(`${apiUrl}/${newsName}/?api=true`, {
    headers: {
        // 'Content-Type': 'application/json'
    },

    })
      // Once we get a response and store data, let's change the loading state
      .then(response => {
        this.setState({
          newslist: response.data,
          isLoading: false
        });

      })
      // If we catch any errors connecting, let's update accordingly
            .catch(error => {
                console.log(error);
                    window.location.href = '/not-found';

                this.setState({ error, isLoading: false } )
            });
  }
  // Let's our app know we're ready to render the data
  componentDidMount() {

    this.getPosts();




  }


  htmlToReactWithReplace() {
    const { newslist } = this.state;
    if (newslist[0] !== undefined) {
      var parsedcontent = String(newslist[0].news_full_description);
    } else {
      parsedcontent = ''
    }

    const replace = domNode => {
      if (domNode.type === 'tag' && domNode.name === 'a' && domNode.attribs.class === 'routed') {
        // console.log(domNode);
        return (
          <Link to={domNode.attribs.href}>{domToReact(domNode.children)}</Link>
        );
      }

      if (domNode.type === 'tag' && domNode.name === 'a') {
        // Validate Twitter URL
        let tweetIdfromUrl;
        let instagramUrl;
        let tikTokUrl;
        var str = domNode.attribs.href;
        if (str.match(/^https?:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(?:es)?\/(\d+)(?:\/.*)?$/)) {
          tweetIdfromUrl = str.match(/^https?:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(?:es)?\/(\d+)(?:\/.*)?$/)[2];
        return (
          <TweetEmbed tweetId={tweetIdfromUrl} options={{lang: 'pl' }} />
        );
        }
        if (str.match(/(https?:\/\/(?:www\.)?instagram\.com\/p\/([^/?#&]+)).*/g) || str.match(/(https?:\/\/(?:www\.)?instagram\.com\/reel\/([^/?#&]+)).*/g)) {
          // data-instgrm-captioned  <-- currently unsupported - TO CHECK PROGRESS on github
          instagramUrl = str;
          return (
              <InstagramEmbed url={instagramUrl} />
          );
        }
        if (str.match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]tiktok+)\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/)) {
          tikTokUrl = str;
          return (
              <TikTokEmbed url={tikTokUrl} />
          );
        }

      }

    };


    return parse(parsedcontent, { replace });
  }


  // Putting that data to use
  render() {
    setTimeout(() => {
            if (window.instgrm) {
                window.instgrm.Embeds.process()
            }
    }, 2000);
    const { isLoading, newslist } = this.state;
    console.log(newslist);
    let modalEnabled;
    let modalData;
    if (newslist[0] !== undefined) {
      if (newslist[0].modalEnabled !== undefined) {
        modalEnabled = newslist[0].modalEnabled;
        modalData = newslist[0].modalData;
      } else {
        modalEnabled = false;
      }
    } else {
      modalEnabled = false;
    }
    console.log(modalEnabled);
     return (
      <React.Fragment>
        {modalEnabled === true ? (<ModalElement title={modalData.modalTitle} text={modalData.modalText} textAccept={modalData.modalAcceptText} textDecline={modalData.modalDeclineText} />) : null}
    <div className="news-single">
        <div>
          {!isLoading ? (
            newslist.map(news => {

              const { id, title, news_image, news_date_added, news_full_description } = news;
              document.title = title + " - Radio Kampus 97,1FM #SAMESZTOSY";
              let news_date = new Date(news_date_added).toLocaleDateString('pl-PL', {
                  weekday: 'long',
                  month: 'numeric',
                  day: 'numeric',
                  year: 'numeric',
              })


              return (
                <div className="news-single-element" key={id}>

                  <h2>{title}</h2>
                  <span className="news--datetime">{news_date}</span>
                  <div className="news--image"><img src={news_image} alt="" /></div>
                  <div className="news--full-description">
                  {this.htmlToReactWithReplace()}
                  </div>
                </div>
              );
            })
          ) : (
            <p>Wczytywanie...</p>
          )}
        </div>
        </div>

      </React.Fragment>
    );
  }
}

export default NewsSingle;
import React from "react";
import {
  useParams
} from "react-router-dom";
import axios from 'axios';
import {apiUrl} from './../Config/ApiSettings';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays } from 'date-fns';
import pl from "date-fns/locale/pl"; // the locale you want
registerLocale("pl-PL", pl); // register it with the name you want

function DayPlaylistElementContainer() {

  let { playlistDate } = useParams();
  return (
  	<div>
<DayPlaylistElement path={playlistDate} /></div>
  	)
}


class DayPlaylistElement extends React.Component {
  // State will apply to the posts object which is set to loading by default
  state = {
    songs: [],
    startDate: new Date(),
    isLoading: true,
    errors: null,
  };

  handleChange = date => {
    this.setState({
      startDate: date,
    });
    let playlist_day_query = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().slice(0,10).replace(/-/g,"");
    this.getPosts(playlist_day_query);
  };



  // Now we're going to make a request for data using axios
  getPosts( date ) {

          if (date) {
            console.log (" selected date: ", date);
          }

        let playlist_day_query = date;


        // GET PATH FROM URL

        // playlist_day_query = this.props.path;
        // console.log(date);
        // console.log(this.props.path);
        if ((date === undefined) && (this.props.path !== undefined) ) {
          playlist_day_query = this.props.path;
        } else {

            if (playlist_day_query === undefined) {
                let date = new Date(); // Or the date you'd like converted.
                playlist_day_query = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().slice(0,10).replace(/-/g,"");
                console.log("today: " + playlist_day_query);
            }

        }



    axios
      // This is where the data is hosted
      .get(`${apiUrl}/playlista/?day=${playlist_day_query}`, {
})
      // Once we get a response and store data, let's change the loading state
      .then(response => {
        this.setState({
          songs: response.data,
          isLoading: false
        });

      })
      // If we catch any errors connecting, let's update accordingly
            .catch(error => {
                console.log(error);
                    // window.location.href = '/not-found';


                this.setState({ error, isLoading: false, songs: null } )
            });
  }
  // Let's our app know we're ready to render the data
  componentDidMount( date ) {
    this.getPosts();
this.interval = setInterval(() => this.getPosts( date ), 120000);

            let scrollPos = 0;
            const nav = document.querySelector('.playlist-section .dynamic-boxes.top-right');
            const nav2 = document.querySelector('.playlist-section .dynamic-boxes.bottom-left');

            function checkPosition() {
              let windowY = window.scrollY;
              if (windowY < scrollPos) {
                // Scrolling UP
                nav.classList.add('is-visible');
                nav.classList.remove('is-hidden');
                nav2.classList.add('is-visible');
                nav2.classList.remove('is-hidden');
              } else {
                // Scrolling DOWN
                nav.classList.add('is-visible');
                nav.classList.remove('is-hidden');
                nav2.classList.add('is-visible');
                nav2.classList.remove('is-hidden');
              }
              scrollPos = windowY;
            }



            function debounce(func, wait = 10, immediate = true) {
              let timeout;
              return function() {
                let context = this, args = arguments;
                let later = function() {
                  timeout = null;
                  if (!immediate) func.apply(context, args);
                };
                let callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) func.apply(context, args);
              };
            };

            window.addEventListener('scroll', debounce(checkPosition))




            var isScrolling;

            // Listen for scroll events
            window.addEventListener('scroll', function ( event ) {
              // var startScrolling = setTimeout(function() {
                // Run the callback
                // console.log( 'Scrolling has started.' );
              // }, 1000);

              // Clear our timeout throughout the scroll
              window.clearTimeout( isScrolling );

              // Set a timeout to run after scrolling ends
              isScrolling = setTimeout(function() {
                nav.classList.remove('is-visible');
                nav2.classList.remove('is-visible');
                // Run the callback
                // console.log( 'Scrolling has stopped.' );

              }, 66);

            }, false);














  }


  componentWillUnmount() {
    clearInterval(this.interval);
  }


componentDidUpdate(prevProps, prevState) {
  // only update chart if the data has changed

  if (prevProps.path !== this.props.path) {
    this.getPosts();
  }
}



// static getDerivedStateFromProps(nextProps, prevState) {

//     this.getPosts();

// }

  // Putting that data to use
  render() {
    const { isLoading, songs } = this.state;
        let currentPath = this.props.path;
        let todayInit = new Date().toLocaleDateString('pl-PL', {
            weekday: 'long'
        })
        let currentDay = todayInit.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\u0142/g, "l");
        // if ((currentPath == currentDay) || (!currentPath)) {
        //   console.log("prawda to")} else {console.log("bullszit")};
        //  ((currentPath == currentDay) || (!currentPath)) ? console.log("prawda to") : console.log("bullszit");
        let playlist_query = this.state.startDate.toLocaleDateString('pl-PL', {
            weekday: 'long'
        })

        // console.log(songs);
        // console.log(isLoading);
    if (songs === null) {
        // let songs = [{
        //   song_id : "1",
        //   artist : "Same",
        //   title : "Sztosy",
        // }, {
        //   song_id : "1",
        //   artist : "Same",
        //   title : "sztosy!",
        // }]

        // let secondToLastSong = songs[1];

    return (
      <React.Fragment>
        <div className="select-day-container"><div className="select-day-heading">Wybierz dzień:</div>
          <DatePicker
          selected={this.state.startDate}
          onChange={this.handleChange}
          dateFormat="yyyy-MM-dd"
          locale="pl-PL"
          minDate={new Date('2015-03-01')}
          maxDate={addDays(new Date(), 0)}
          onFocus={(e) => e.target.readOnly = true}
          popperPlacement="bottom"
          popperModifiers={{
              flip: {
                  behavior: ["bottom"] // don't allow it to flip to be above
              },
              preventOverflow: {
                  enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
              },
              hide: {
                  enabled: false // turn off since needs preventOverflow to be enabled
              }
          }}
          />
      </div>
        <h3 className="subpage-title">{ (((currentPath === currentDay) || (!currentPath)) ? playlist_query : currentPath)  }</h3>
          {!isLoading ? (
                <ul className={"playlist-list"}><li>Brak utworów na playliście.</li></ul>

          ) : (
            <div className="played-song"><span className="song-title">Wczytywanie...</span></div>
          )}
      </React.Fragment>
    );

  }
  else {
    // console.log(songs)



        // console.log(this.props);
    return (
      <React.Fragment>
        <div className="select-day-container"><div className="select-day-heading">Wybierz dzień:</div>
          <DatePicker
          selected={this.state.startDate}
          onChange={this.handleChange}
          dateFormat="yyyy-MM-dd"
          locale="pl-PL"
          minDate={new Date('2015-03-01')}
          maxDate={addDays(new Date(), 0)}
          onFocus={(e) => e.target.readOnly = true}
          popperPlacement="bottom"
          popperModifiers={{
              flip: {
                  behavior: ["bottom"] // don't allow it to flip to be above
              },
              preventOverflow: {
                  enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
              },
              hide: {
                  enabled: false // turn off since needs preventOverflow to be enabled
              }
          }}
          />
      </div>
        <h3 className="subpage-title">{ (((currentPath === currentDay) || (!currentPath)) ? playlist_query : currentPath)  }</h3>
        <ul className={"playlist-list " + (((currentPath === currentDay) || (!currentPath)) ? 'current-day' : '')}>
          {!isLoading ? (
            (songs.length !== 0) ? ( songs.map(song => {
              const { song_id, start_time, artist, title } = song;


              return (
                <li className="playlist-item" key={song_id}>
                  <span className="play-time">{start_time}</span>
                  <span className="song_container"><a href={"https://www.google.com/search?q=" + encodeURIComponent(artist) + '%20-%20' + encodeURIComponent(title) } target="_blank" rel="noopener noreferrer"><span className="artist">{artist}</span> - <span className="song-title">{title}</span></a></span>
                </li>

              );
            }) ) : (<p>Nie znaleziono playlisty.</p>)
          ) : (
            <p>Wczytywanie...</p>
          )}
        </ul>
      </React.Fragment>
    );
  }
  }
}

export default DayPlaylistElementContainer;
import React from "react";
import { useAPI } from "./../Service/HomeDataContext";

export default function MenuContactData() {
  const { homedata } = useAPI();
  // console.log(homedata);

  let contactData = [];
  if (homedata[0] !== undefined ) {
      contactData = homedata[0].menu_contact[0];
    };

  return (
      <div className="menu-column--contact">
              <h2 className="subpage-title">{contactData.title}</h2>
              <div className="contact-data">
                <div className="email-contact" dangerouslySetInnerHTML={ { __html: contactData.field_1 }} />
                <div className="email-contact" dangerouslySetInnerHTML={ { __html: contactData.field_2 }} />
                <div className="email-contact" dangerouslySetInnerHTML={ { __html: contactData.field_3 }} />
                <div className="email-contact" dangerouslySetInnerHTML={ { __html: contactData.field_4 }} />
                <div className="phone-contact" dangerouslySetInnerHTML={ { __html: contactData.field_5 }} />
              </div>        
      </div>



  );
}
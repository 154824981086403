import React from "react";
import SummaryPageElementContainer from '.././Components/SummaryPageElement';
import SummaryVote from '.././Components/SummaryVote';
import Breadcrumbs from '.././Components/Breadcrumbs';

function SummaryPage() {
  return (
    <div className="contact-section alterlator-section">
          <Breadcrumbs />
                <div className="info-container">
                    <SummaryPageElementContainer />
                    <SummaryVote />
                </div>
    </div>
  );
}

export default SummaryPage;
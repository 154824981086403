import React from "react";
import {
  Link,
  useParams
} from "react-router-dom";
import Pagination from './Pagination';
import axios from 'axios';
import {apiUrl} from './../Config/ApiSettings';
import {
    NavLink,
  } from "react-router-dom";
// podcasts update 2024
class PodcastListProgramsResults extends React.Component {

    state = {
        programs: [],
        isLoading: true,
        errors: null,
      };

      getPosts() {
          let search_query = this.props.path;
          let page_number;
          let page_category_query;
          let page_base_url = 'programy';



          // PAGE CATEGORY RULES
              console.log("TYP STRONY: " + page_base_url);
              let page_category_url_split = window.location.pathname.split("/");
                  // console.log(page_category_url_split[2]);
              let page_category_url_pop = page_category_url_split.pop();
                  // console.log(page_category_url_pop);
                  // let page_category_url_start = window.location.pathname.split("/").pop();
              if( !page_category_url_pop ) {
                  page_category_url_pop = page_category_url_split.pop();
              }

              var lastChar = window.location.pathname.substr(-1); // Selects the last character
                  if (lastChar !== '/') {         // If the last character is not a slash

                  }

              if ((window.location.pathname === '/'+page_base_url+'') || (window.location.pathname === '/'+page_base_url+'/')) {
                  // console.log("TAK");
                  page_category_query = '&toplevel=true';
              } else if (document.location.pathname.indexOf('/'+page_base_url+'/page') === 0) {
                  page_category_query = '&toplevel=true';
              } else {
                  // console.log("NIE");
                  let page_category_url;
                  if (window.location.pathname.indexOf('/page') >= 0) {
                      page_category_url = window.location.pathname.split("/")[3];
                  } else {
                      page_category_url = page_category_url_pop.replace(/\/$/, '');
                  }
                  page_category_query = page_category_url;
                  console.log("KEYWORDS:" + page_category_url);
                  search_query = page_category_url;
              }

              // Check page URL
              if( window.location.pathname.indexOf('/page') >= 0) {
                  page_number = window.location.pathname.split("page")[1];
                  // console.log("Wybrana strona: " + page_number);
              } else {
                  page_number = '1';
                  // console.log("Strona poczatkowa")
              }

              const urlParams = new URLSearchParams(window.location.search);
              const allPrograms = urlParams.get('all');
              let isAll = '';
            //   console.log(allPrograms);
              if (allPrograms === '') {
                isAll = '&all';
              }
          // END PAGE CATEGORY RULES

          if (search_query) {
                isAll = '&search&q='+search_query;
          }


          axios
              .get(`${apiUrl}/${page_base_url}/?api=true&nolimit&podcasts_list${isAll}`, {
                  headers: {
                      // 'Content-Type': 'application/json'
                  },

              })
              // Once we get a response and store data, let's change the loading state
              .then(response => {
                  this.setState({
                      programs: response.data,
                      isLoading: false
                  });
              })
              // If we catch any errors connecting, let's update accordingly
              .catch(error => {
                  console.log(error);
                      window.location.href = '/not-found';

                  this.setState({ error, isLoading: false } )
              });
      }

      componentDidMount() {
          this.getPosts();
      }

      componentDidUpdate(prevProps, prevState) {
          if (prevProps.path !== this.props.path) {

              this.setState({
                  isLoading: true,
              });
              this.getPosts();

          }
      }

      render() {
          let page_number;
          const { isLoading, programs } = this.state;

          let page_path = window.location.pathname.split('/page')[0];
              // console.log(page_path);
              // DUPLICATED PAGE NUMBER
          if( window.location.pathname.indexOf('/page') >= 0) {
              page_number = window.location.pathname.split('/page')[1];
              console.log("Wybrana strona: " + page_number);
          } else {
              page_number = '1';
              // console.log("Strona poczatkowa")
          }
              // END DUPLICATION

          if (programs !== null) {
                console.log("SLZ CZEKAP");
                console.log(programs);
              if (programs[0] !== undefined ) {
                  //    PAGINATION
                  //    console.log(programs);
                  //    console.log(programs[0].pages_total)
                  let number_of_pages = programs[0].pages_total;
                  const page_numbers = [];




                  ////////////////////////////////////////////////////////////////////////////////
                  Pagination( number_of_pages, page_numbers, page_number, page_path );
                  ////////////////////////////////////////////////////////////////////////////////




                  return (
                      <React.Fragment>
                          <div className="podcast-list">
                          {!isLoading ? (
                              (programs !== null) ? ( (programs.map(program => {

                                const { id, title, name, image, programy_kategorie, program_short_description } = program;
                                let programUrl = '/podcasty/' + name;
                                return (
                                    <div className="podcast-item" key={id}>
                                    <Link to={programUrl}><img src={image} className="podcast--image" alt="" /></Link>
                                    <h3 className="podcast--title"><Link to={programUrl}>{title}</Link></h3>
                                    <p>{program_short_description}</p>
                                    <span className="podcasts-metadata">
                                        <span className="podcast--tags">#{programy_kategorie}</span>
                                    </span>
                                </div>
                                )
                              }))  ) : (<p>Nie znaleziono podcastów.</p>)
                          ) : (
                              <p>Wczytywanie podcastów...</p>
                          )}
                          </div>
                          <ul className="pagination">
                              {page_numbers}
                          </ul>
                      </React.Fragment>
                  );

              } else {
                  return (
                      <React.Fragment>
                          {!isLoading ? (
                              <p>Nie znaleziono podcastów.</p>
                            ) : (
                                <p>Wczytywanie podcastów...</p>
                            )}
                      </React.Fragment>
                  );
              }
          } else {
              return <p>Nie znaleziono podcastów.</p>
          }
      }
}


export default PodcastListProgramsResults;
import React from "react";
import { useAPI } from "./../Service/HomeDataContext";

export default function Partners() {
  const { homedata } = useAPI();
  // console.log(homedata);

  let partners_list = [];
  let partnersData = [];
  if (homedata[0] !== undefined ) {
      partnersData = homedata[0].partners[0];
      // console.log(homedata[0].partners[0]);
      partners_list = partnersData.partners_list;
    };

  return (
    <div className="cathedras-section">
        <div className="dynamic-boxes">
            <div className="box-1"> </div>
            <div className="box-2"> </div>
            <div className="box-3"> </div>
        </div>
        <h2 className="">{partnersData.partners_title}</h2>
        <div dangerouslySetInnerHTML={ { __html: partnersData.partners_description }} />
        <div className="cathedras-list">
            {partners_list.map(u => (
                <a key={u.id} href={u.url} target="_blank" rel="noopener noreferrer"><img src={u.logo} alt={u.title} /></a>
            ))}
        </div>

    </div>



  );
}
import React from "react";
import {
  Switch,
  Route,
  Redirect,
  NavLink,
  useRouteMatch,
} from "react-router-dom";
import WeekPlaylistElementContainer from '.././Components/WeekPlaylistElement';
import Breadcrumbs from '.././Components/Breadcrumbs';

function WeekPlaylist() {
  let match = useRouteMatch();


function navigationTrigger() {
      let selectCategoryText = document.querySelector('.navigation-filter .select-category');
      let navTarget = document.querySelector('.navigation-filter');
      navTarget.classList.toggle('active');
      selectCategoryText.classList.toggle('hidden'); 
}

function selectCategory() {
      let selectCategoryText = document.querySelector('.navigation-filter .select-category');     
      selectCategoryText.classList.remove('active');      
}

document.title = "Ramówka - Radio Kampus 97,1FM #SAMESZTOSY"; 

        let todayInit = new Date().toLocaleDateString('pl-PL', {
            weekday: 'long'
        })
        let todayInitClean = todayInit.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\u0142/g, "l");

    return (
        <div className="schedule-section">

          <Breadcrumbs />
                <div className="content-header with-filter">
					<h2 className="subpage-title"><br />Ramówka</h2>
          <ul className="navigation-filter" onClick={navigationTrigger}><span className="dropdown-button"></span> <span className="select-category active">Wybierz</span>
						<li><NavLink onClick={selectCategory} name="Poniedziałek" to={{ pathname: `${match.url}/poniedzialek`, weekday: 'Poniedziałek' }}>Poniedziałek</NavLink></li>
						<li><NavLink onClick={selectCategory} name="Wtorek" to={`${match.url}/wtorek`}>Wtorek</NavLink></li>
						<li><NavLink onClick={selectCategory} name="Środa" to={`${match.url}/sroda`}>Środa</NavLink></li>
						<li><NavLink onClick={selectCategory} name="Czwartek" to={`${match.url}/czwartek`}>Czwartek</NavLink></li>
						<li><NavLink onClick={selectCategory} name="Piątek" to={`${match.url}/piatek`}>Piątek</NavLink></li>
						<li><NavLink onClick={selectCategory} name="Sobota" to={`${match.url}/sobota`}>Sobota</NavLink></li>
						<li><NavLink onClick={selectCategory} name="Niedziela" to={`${match.url}/niedziela`}>Niedziela</NavLink></li>
					</ul>
				</div>     
        <div className="schedule-background"> 
          <div className="dynamic-boxes top-right">
            <div className="box-1"> </div>
            <div className="box-2"> </div>
            <div className="box-3"> </div>
          </div>   
          <div className="dynamic-boxes bottom-left">
            <div className="box-1"> </div>
            <div className="box-2"> </div>
            <div className="box-3"> </div>
          </div>    
        </div>
          <div className="schedule-container">

            <Switch>

                <Route path={`${match.path}/:scheduleDay`}>
                   <WeekPlaylistElementContainer />                      
                </Route>

                <Route path="/ramowka/">
                   <Redirect to={`${match.path}/${todayInitClean}/`} />                 
                </Route>

  		      </Switch>

         </div>
       </div>
    );
  }

export default WeekPlaylist;
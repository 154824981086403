import React from 'react';
import {
  Route,
  Link,
} from "react-router-dom";
import BreadcrumbTitle from './BreadcrumbTitle';

const Breadcrumbs = (props) => (
    <div className="breadcrumbs">
        <ul className='container'>
            <li className="home-path"> <Link to="/">strona główna</Link></li>
            <Route path='/:path' component={BreadcrumbsItem} />
        </ul>
    </div>
)

const BreadcrumbsItem = ({ match, ...rest }) => {

  var path = match.url
  var paths = path.split( "/" ),
      breadcrumb_path = paths.pop( );
    // console.log( " CO TU SIE: ---- " + breadcrumb_path );
  if( !breadcrumb_path ) {
    breadcrumb_path = paths.pop( );
    // console.log( " CO TU SIE: ---- " + breadcrumb_path );
  }
  breadcrumb_path=breadcrumb_path.replace("-"," ");
  let pagination_title = '';
 if (breadcrumb_path.indexOf("page") > -1) {
    pagination_title = breadcrumb_path.replace("page", "strona #");
 }

  return (

    <span>
        <li className={match.isExact ? 'breadcrumb-active' : undefined}>
            <Link to={match.url || ''}>
             {/* breadcrumb_path */}

 { (breadcrumb_path.indexOf("page") > -1) ? ( pagination_title ) : (  <BreadcrumbTitle path={`${match.url}`} /> )  }


                {/*<BreadcrumbTitle path={breadcrumb_path} />*/}
            </Link>
        </li>
        <Route path={`${match.url}/:path`} component={BreadcrumbsItem} />
    </span>
    )
}


export default Breadcrumbs;
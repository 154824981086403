import React from "react";
import axios from 'axios';
import {apiUrl} from './../Config/ApiSettings';
import {
  NavLink,
} from "react-router-dom";

class ProgramListCategories extends React.Component {

  state = {
    categories: [],
    isLoading: true,
    errors: null
  };


  getPosts() {

    axios
      .get(`${apiUrl}/programy/?categories_list=true`, {
    headers: {
        // 'Content-Type': 'application/json'
    },

    }) 
      .then(response => {
        this.setState({
          categories: response.data,
          isLoading: false
        });
                      
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  componentDidMount() {
    this.getPosts();
  }

  componentDidUpdate(prevProps, prevState) {

    if (prevProps.path !== this.props.path) {
              this.setState({
                  isLoading: true,
              });
              this.getPosts();
    }
  }


  render() {

    function navigationTrigger() {
          let selectCategoryText = document.querySelector('.navigation-filter .select-category');
          let navTarget = document.querySelector('.navigation-filter');
          navTarget.classList.toggle('active');
          selectCategoryText.classList.toggle('hidden'); 
    }

    function selectCategory() {
          let selectCategoryText = document.querySelector('.navigation-filter .select-category');     
          selectCategoryText.classList.remove('active');      
    }

    const { isLoading, categories } = this.state;
     return (
      <React.Fragment>
      <ul className="navigation-filter" onClick={navigationTrigger}><span className="dropdown-button"></span> <span className="select-category active">Wybierz</span>
          {!isLoading ? (
            categories.map(category => {

              const { id, title, url } = category;
              return (
                <li key={id}><NavLink onClick={selectCategory} to={`${url}`}>{title}</NavLink></li>
              );
            })
          ) : (
            <div className="loading-container"><p>Wczytywanie...</p></div>
          )}
          </ul>
      </React.Fragment>
    );
  }
}

export default ProgramListCategories;
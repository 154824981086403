import React from 'react';

class ScrollButton extends React.Component {
  constructor() {
    super();

    this.state = {
        intervalId: 600
    };
  }
  
  scrollToTop() {
    window.scroll({top: 0, behavior: 'smooth' })
  }
  
  render () {
      return <button title='Back to top' className='scroll' 
               onClick={ () => { this.scrollToTop(); }}>
                <span className='arrow-up glyphicon glyphicon-chevron-up'>&nbsp;</span>
              </button>;
   }
} 

export default ScrollButton;
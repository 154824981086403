import React from "react";
import {
  useParams
} from "react-router-dom";
import axios from 'axios';
import {apiUrl} from './../Config/ApiSettings';


function AlterlatorPageElementContainer() {
  let { infoPageId } = useParams();
  return (
    <div>
< AlterlatorPageElement path = {
    infoPageId
}
/></div >
    )
}


class AlterlatorPageElement extends React.Component {
  // State will apply to the posts object which is set to loading by default
  state = {
    pages: [],
    isLoading: true,
    errors: null,
  };





  // Now we're going to make a request for data using axios
  getPosts() {




    axios
      // This is where the data is hosted
      .get(`${apiUrl}/alterlator/?api=true`, {
    headers: {
        // 'Content-Type': 'application/json'
    },

    })

      // Once we get a response and store data, let's change the loading state
      .then(response => {
        this.setState({
          pages: response.data,
          isLoading: false
        });

      })
      // If we catch any errors connecting, let's update accordingly
            .catch(error => {
                console.log(error);
                     window.location.href = '/not-found';

                this.setState({ error, isLoading: false } )
            });
  }
  // Let's our app know we're ready to render the data
  componentDidMount() {

  const { isLoading } = this.state;

  if (isLoading) {
    this.getPosts();
  }







  }


componentDidUpdate(prevProps, prevState) {
  // only update chart if the data has changed
  if (prevProps.path !== this.props.path) {

     this.setState({
       isLoading: true
     })

    this.getPosts();
  }
}


  // Putting that data to use
  render() {
    const { isLoading, pages } = this.state;



    return (
      <React.Fragment>

          {!isLoading ? (
            pages.map(page => {
              const { id, title, info_page_content } = page;
              document.title = title + " - Radio Kampus 97,1FM #SAMESZTOSY";
              return (
            <div key={id} className="info-single-container">
                    <h2 className="subpage-title">{title}</h2>





                  <div className="info-description">

                    <div dangerouslySetInnerHTML={ { __html: info_page_content }} />
                  </div>

              </div>
              );
            })
          ) : (
            <p>Wczytywanie...</p>
          )}
      </React.Fragment>
    );
  }
}

export default AlterlatorPageElementContainer;
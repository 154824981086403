import React from "react";
import axios from "axios";
import { apiUrl } from "./../Config/ApiSettings";

class AlterlatorVote extends React.Component {
    // State will apply to the posts object which is set to loading by default
    state = {
        allsongs: [],
        isLoading: true,
        isSubmitted: false,
        errors: null,
    };

    // Now we're going to make a request for data using axios
    getPosts() {
        axios
            // This is where the data is hosted
            .get(`${apiUrl}/vote/api/`, {
                headers: {
                    // 'Content-Type': 'application/json'
                },
            })

            // Once we get a response and store data, let's change the loading state
            .then((response) => {
                this.setState({
                    allsongs: response.data,
                    isLoading: false,
                    isSubmitted: false,
                });

                console.log(response.data);
            })
            // If we catch any errors connecting, let's update accordingly
            .catch((error) => {
                console.log(error);
                //window.location.href = '/not-found';

                this.setState({ error, isLoading: false });
            });
    }
    // Let's our app know we're ready to render the data
    componentDidMount() {
        const { isLoading } = this.state;

        if (isLoading) {
            this.getPosts();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // only update chart if the data has changed
        if (prevProps.path !== this.props.path) {
            this.setState({
                isLoading: true,
            });

            this.getPosts();
        }
    }

    onSubmitForm = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const body = {};
        let voteInfo = document.getElementById("submit-info");
        formData.forEach((value, property) => (body[property] = value));
        //here you can update, remove, add values/properties in the body object this is specially usefull if any custom process must be done to check, encrypt data or wherever you want.
        // console.table(body)
        // Request goes here.

        function isOneChecked() {
            // All <input> tags...
            var chx = document.getElementsByTagName("input");
            for (var i = 0; i < chx.length; i++) {
                // If you have more than one radio group, also check the name attribute
                // for the one you want as in && chx[i].name == 'choose'
                // Return true from the function on first match of a checked item
                if (chx[i].type === "radio" && chx[i].checked) {
                    return true;
                }
            }
            // End of the loop, return false
            return false;
        }
        console.log(isOneChecked());

        if (isOneChecked()) {
            axios
                .post(`${apiUrl}/vote/submit/`, formData)
                .then((response) => {
                    this.setState({
                        isLoading: false,
                        isSubmitted: true,
                    });

                    // console.log(res);
                    let voteButton = document.getElementById("submit-vote");
                    let voteList = document.querySelector(".vote-list");
                    voteButton.classList.add("done");
                    voteList.classList.add("done");
                    voteInfo.classList.add("done");
                    // voteInfo.innerHTML("Dziękujemy za zagłosowanie!");
                })

                .catch((error) => {
                    console.log(error);
                    this.setState({
                        error,
                        isLoading: false,
                        isSubmitted: "error",
                    });
                    return error.response;

                    // console.log(err.response);
                });
        } else {
            console.log("Empty radio fields");
            this.setState({
                isLoading: false,
                isSubmitted: "empty",
            });
        }
    };

    // Putting that data to use
    render() {
        const { isLoading, isSubmitted, allsongs } = this.state;
        //  console.log(allsongs);
        let vote_possible;
        let vote_day;
        if (allsongs.settings) {
            //  console.log(allsongs.settings.can_vote);

            vote_possible = allsongs.settings.can_vote;
            vote_day = allsongs.settings.vote_day;
        }

        return (
            <React.Fragment>
                <div className="vote-list">
                    <div>
                        <form method="post" onSubmit={(e) => this.onSubmitForm(e)}>
                            {!isLoading ? <input type="hidden" name="vote_day" id="vote_day" value={vote_day} /> : ""}
                            {!isLoading ? (
                                allsongs.vote_data !== null && vote_possible !== null ? (
                                    allsongs.vote_data.map((song) => {
                                        const { pair_id, songs } = song;
                                        return (
                                            <fieldset id={"group" + pair_id} key={pair_id}>
                                                <legend>Para #{pair_id}</legend>
                                                {songs.map((song) => {
                                                    const { id, name } = song;
                                                    return (
                                                        <p key={id}>
                                                            {vote_possible === "true" ? (
                                                                <input
                                                                    type="radio"
                                                                    name={"pair_" + pair_id}
                                                                    id={"song" + id}
                                                                    value={id}
                                                                />
                                                            ) : (
                                                                ""
                                                            )}
                                                            <label htmlFor={"song" + id}> {name}</label>
                                                        </p>
                                                    );
                                                })}
                                            </fieldset>
                                        );
                                    })
                                ) : (
                                    <p>Nie znaleziono podcastów.</p>
                                )
                            ) : (
                                <p></p>
                            )}
                            {vote_possible === "true" ? <input type="submit" id="submit-vote" value="Głosuj" /> : ""}
                        </form>
                        {!isLoading ? (
                            <div
                                id="submit-info"
                                className={
                                    isSubmitted === true
                                        ? "success"
                                        : isSubmitted === "error"
                                        ? "failed"
                                        : isSubmitted === "empty"
                                        ? "failed"
                                        : vote_possible === "true"
                                        ? ""
                                        : "disabled"
                                }
                            >
                                {isSubmitted === true
                                    ? "Dziękujemy za zagłosowanie!"
                                    : isSubmitted === "error"
                                    ? "Błąd wysyłania"
                                    : isSubmitted === "empty"
                                    ? "Wybierz co najmniej jeden utwór"
                                    : vote_possible === "true"
                                    ? ""
                                    : "Ktoś już oddał głos z\u00A0tego adresu IP. Wróć tutaj na następne głosowanie!"}
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AlterlatorVote;

import React from "react";
import {
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import DayPlaylistElementContainer from '.././Components/DayPlaylistElement';
import Breadcrumbs from '.././Components/Breadcrumbs';




function DayPlaylist() {
  let match = useRouteMatch();
  document.title = "Playlista - Radio Kampus 97,1FM #SAMESZTOSY"; 

    return (
        <div className="playlist-section">

          <Breadcrumbs />
        <div className="content-header with-filter">
					<h2 className="subpage-title">Playlista <br/>Radia Kampus</h2>
					<ul className="navigation-filter">
					</ul>
				</div>   
        <div className="playlist-background"> 
          <div className="dynamic-boxes top-right">
            <div className="box-1"> </div>
            <div className="box-2"> </div>
            <div className="box-3"> </div>
          </div>   
          <div className="dynamic-boxes bottom-left">
            <div className="box-1"> </div>
            <div className="box-2"> </div>
            <div className="box-3"> </div>
          </div>    
        </div>
          <div className="playlist-container">

           <Switch>

             <Route path={`${match.path}/:playlistDate`}>
             <DayPlaylistElementContainer />                      
            </Route>

          <Route path="/playlista/">
             <DayPlaylistElementContainer />                       
            </Route>

		   </Switch>

         </div>
       </div>
    );
  }

export default DayPlaylist;
import React, { useState, useRef } from 'react';
import { useOnClickOutside } from '.././hooks';
import {
  useRouteMatch  
} from "react-router-dom";
import MainMenuTrigger from './MainMenuTrigger';
import Menu from './MainMenu';


function Header() {
		const [open, setOpen] = useState(false);
		const node = useRef(); 
		const menuId = "main-page-menu";

		useOnClickOutside(node, () => setOpen(false));
		useRouteMatch();
		var path = window.location.pathname;
				// console.log(match);
				console.log("Current PATH: " + path);
		path = path[0] === '/' ? path.substr(1) : path;
		let myHome = path;
  			// console.log(myHome);
		let pageUrlClassName
		if (myHome === '') {
	  		pageUrlClassName = 'home'
	  } else {
	  		pageUrlClassName = '';
		}

		return (
				<header className={"header-container " + pageUrlClassName}>

							<nav className="menu-container top-menu">
									<div className="top-menu-wrap" ref={node}>
											<Menu open={open} setOpen={setOpen} />
											<MainMenuTrigger open={open} setOpen={setOpen} aria-controls={menuId}  />
									</div>					
							</nav>

				</header>
		)
}

export default Header;
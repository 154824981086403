import React from "react";
import AlterlatorPageElementContainer from '.././Components/AlterlatorPageElement';
import AlterlatorVote from '.././Components/AlterlatorVote';
import Breadcrumbs from '.././Components/Breadcrumbs';

function AlterlatorPage() {
  return (
    <div className="contact-section alterlator-section">
          <Breadcrumbs />    
                <div className="info-container">
                    <AlterlatorPageElementContainer /> 
                    <AlterlatorVote />
                </div>
    </div>
  );
}

export default AlterlatorPage;
import React from 'react';
import {
  withRouter
} from 'react-router-dom';
import IconSearch from './../Assets/img/search_icon.svg';
import IconSearchArrow from './../Assets/img/search_arrow_icon.svg';


class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {value: '', active: false};

    this.textInput = null;

    this.setTextInputRef = element => {
      this.textInput = element;
    };

    this.focusTextInput = () => {
      // Focus the text input using the raw DOM API
      this.setState({active: !this.state.active});
      if (this.textInput) this.textInput.focus();

    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.textInput = React.createRef();
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    this.props.setOpen(false);
    this.props.history.push('/szukaj/' + this.state.value);
    const menuTop = document.querySelector('.top-menu-wrap .menu-container.top-menu');
    const menuTop2 = document.querySelector('.top-menu--dropdown .main-page-menu');
    const bodyLocker = document.querySelector('body,html');
    const bodyLocked = document.querySelector('html.locked');
    if (bodyLocked) { bodyLocker.classList.toggle('locked');}
    menuTop.classList.remove('open');
    menuTop2.classList.remove('open');
    menuTop.classList.add('closed');
    menuTop2.classList.add('closed');
    event.preventDefault();
  }

  render() {
    return (
      <div className="search-bar-container">
      <form name="search-top" onSubmit={this.handleSubmit}>
      <button type="button" className={this.state.active ? 'active' : 'static'}
      onClick={this.focusTextInput}><img src={IconSearch} alt="szukaj" /></button>
        <div className="search-input-area">
          <input id="search_top" ref={this.setTextInputRef} type="text" pattern=".{3,}" maxLength="100" required title="Minimum 3 znaki" value={this.state.value} onChange={this.handleChange} />
        <input type="image" src={IconSearchArrow} alt="Szukaj" />
        </div>
      </form>
      </div>
    );
  }
}

export default withRouter(SearchBar);
import React from "react";
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
} from "react-router-dom";
import CustomPageElementContainer from '.././Components/CustomPageElement';
import Breadcrumbs from '.././Components/Breadcrumbs';

function CustomPage() {
  let match = useRouteMatch();





    return (
        <div className="custom-page-section">
          <Breadcrumbs />
                <div className="content-header with-filter">
				</div>
          <div className="custom-page-container">

           <Switch>


             <Route path={`${match.path}/:customPageId/:customSubPageId?/:customSub2PageId?`}>
             <CustomPageElementContainer />
            </Route>



          <Route path="/akcje">
           <Redirect to={`${match.path}/takbrzmi2021`} />
            </Route>

		   </Switch>

         </div>
       </div>
    );
  }

export default CustomPage;
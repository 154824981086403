import React from "react";
import { useAPI } from "./../Service/HomeDataContext";
import LogoKampus1 from './../Assets/img/K_1_white.svg';
import LogoKampus3 from './../Assets/img/K_3_white_Baner.svg';

export default function HeaderBanner() {

  const { homedata } = useAPI();
  // console.log(homedata);

  let bannersData = [];
  let currentBanner = [];
  currentBanner.active = 0;
  if (homedata[0] !== undefined ) {
      bannersData = homedata[0].banners;
      currentBanner = bannersData[0];
      // console.log(bannersData);
      // console.log(currentBanner.active);
    };


     return (
      <React.Fragment>
  
            		{(bannersData.map(banner => {

				                const { id, banner_image, url, active } = banner;
				                return (
				                    (active !== 0) ? ( <span className="banner-container" key={id}>
																	<a href={url} target="_blank" rel="noopener noreferrer"> <img className="banner" src={banner_image} alt="" /></a>
				                    </span> ) : ( '' )
				                );

            		}))  }

      {(currentBanner.active !== 0) ? (
            <img className="home-logo-3" src={LogoKampus3} alt="Radio Kampus" />
            ) : (
              <img className="home-logo-1" src={LogoKampus1} alt="Radio Kampus" /> )
          }


      </React.Fragment>
    );
  }
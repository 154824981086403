import React from "react";
import {
  NavLink
} from "react-router-dom";



function Pagination( number_of_pages, page_numbers, page_number, page_path ) {

		if (page_path.substr(-1) === '/') {         // If the last character is not a slash
				page_path = page_path.substring(0, page_path.length - 1);
    }


    let i = 0;

    if (number_of_pages > 10) {
		    page_numbers.push(
		        <li className={'page_list_number-' + page_number +  (((i + 1) === parseInt(page_number))  ? ' active' : '')} data-id={(i + 1)}  key={i + 1}>
		          <NavLink aria-current="page" to={`${page_path}/page${i + 1}`}>{i+1}</NavLink>
		        </li>
		    )


	      if ((number_of_pages > 10) && (parseInt(page_number) < number_of_pages - 10)) { 
	          for (let i = parseInt(page_number); i <= number_of_pages; i++) {

	              if ((page_number > 2) && (i === parseInt(page_number))) {
	                  page_numbers.push( 
	                    <li className={'page_list_separator'} key={'pagination-more-first'}>...</li> 
	                  )
	              } 
	              if ((page_number > 2) && (i === parseInt(page_number))) {
	                  page_numbers.push( 
	                    <li className={'page-prev-button page_list_number-' + (parseInt(page_number) - 1)} data-id={(parseInt(page_number) - 1)}  key={'page-prev'}>
	                      <NavLink aria-current="page" to={`${page_path}/page${parseInt(page_number) - 1}`}><span>PREVIOUS</span></NavLink>
	                    </li>
	                  )
	              }




	              if ((i > 1) && (i < parseInt(page_number) + 9) && (parseInt(page_number) < number_of_pages - 10) ) {
	                  page_numbers.push(
	                    <li className={'page_list_number-' + page_number +  (((i) === parseInt(page_number))  ? ' active' : '')} data-id={(i)}  key={i}>
	                      <NavLink aria-current="page" to={`${page_path}/page${i}`}>{i}</NavLink>
	                    </li>
	                  )
	              }
	          

	              if ((i === number_of_pages - 1) && (parseInt(page_number) < number_of_pages - 10)) {
	                  page_numbers.push( 
	                    <li className={'page_list_separator'} key={'pagination-more-last'}>...</li> 
	                  )
	              } 

	              if (i + 1 === number_of_pages && (parseInt(page_number) < number_of_pages - 10)) {
	                  page_numbers.push(
	                    <li className={'page_list_number-' + page_number +  (((i + 1) === parseInt(page_number))  ? ' active' : '')} data-id={(i + 1)}  key={i + 1}>
	                      <NavLink aria-current="page" to={`${page_path}/page${i + 1}`}>{i+1}</NavLink>
	                    </li>
	                  )
	              }
	          }
	      }
	      if (number_of_pages > 10) {
			      if ( (parseInt(page_number) >= number_of_pages - 10)) {

			              if (number_of_pages > 10) {
			                  page_numbers.push( 
			                    <li className={'page_list_separator'} key={'pagination-more-first'}>...</li> 
			                  )


			                  page_numbers.push( 
			                    <li className={'page-prev-button page_list_number-' + (parseInt(page_number) - 1)} data-id={(parseInt(page_number) - 1)}  key={'page-prev'}>
			                      <NavLink aria-current="page" to={`${page_path}/page${parseInt(page_number) - 1}`}><span>PREVIOUS</span></NavLink>
			                    </li>
			                  )
			              }


			          for (let i = number_of_pages - 10; i <= number_of_pages; i++) {



			                  page_numbers.push(
			                    <li className={'page_list_number-' + page_number +  (((i) === parseInt(page_number))  ? ' active' : '')} data-id={(i)}  key={i}>
			                      <NavLink aria-current="page" to={`${page_path}/page${i}`}>{i}</NavLink>
			                    </li>
			                  )
			          }


			      }


			      if ((parseInt(page_number) >= 1) && (parseInt(page_number) < number_of_pages)) {

			                  page_numbers.push(
			                    <li className={'page-next-button page_list_number-' + (parseInt(page_number) + 1)} data-id={(parseInt(page_number) + 1)}  key={'page-next'}>
			                      <NavLink aria-current="page" to={`${page_path}/page${parseInt(page_number) + 1}`}><span>NEXT</span></NavLink>
			                    </li>
			                  )
				    }
		    } 
  	}
    else {
        if (number_of_pages > 1) {
	          for (let i = 0; i < number_of_pages; i++) {

		            page_numbers.push(

		              <li className={'page_list_number-' + page_number +  (((i + 1) === parseInt(page_number))  ? ' active' : '')} data-id={(i + 1)}  key={i + 1}>
		                <NavLink aria-current="page" to={`${page_path}/page${i + 1}`}>{i+1}</NavLink>
		              </li>
		            )
		            
	          }
        }
    }
}



export default Pagination;
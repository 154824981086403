import React from 'react';
import ReactAudioPlayer from './ReactAudioPlayer';
import AudioPlayButton from './AudioPlayButton';
import HeaderBanner from './HeaderBanner';
import LogoKampus2 from './../Assets/img/K_2_white.svg';
import PlayerCurrentProgram from './PlayerCurrentProgram';
import PlayerTopSongs from './PlayerTopSongs';
import {
  useRouteMatch  
} from "react-router-dom";
import { useAPI } from "./../Service/HomeDataContext";

export default function HeaderPlayer() {
        let stream_url = '';
        const { homedata } = useAPI();
        if (homedata[0] !== undefined) {
            stream_url = homedata[0].global_settings.stream_url;
            // console.log(homedata[0].footer[0]);
            console.log(stream_url);
        };

		useRouteMatch();
		var path = window.location.pathname;
		path = path[0] === '/' ? path.substr(1) : path;
		let myHome = path;
				// console.log(myHome);
		let pageUrlClassName
		if (myHome === '') {
	  		pageUrlClassName = 'home'
	  } else {
	  		pageUrlClassName = '';
		}



var myAudio = document.querySelector(".react-audio-player");
var volumeDrag = false;

var volume = document.querySelector('.volume,.volumeBar');
if (volume !== null) {
volume.addEventListener("mousedown", (e) => { 
    volumeDrag = true;
    myAudio.muted = false;
    //   document.querySelector('.sound').classList.remove('muted');
    updateVolume(e.pageX);
});
}
document.addEventListener('mouseup', (e) => { 
    if (volumeDrag) {
        volumeDrag = false;
        updateVolume(e.pageX);
    }
});
document.addEventListener('mousemove', (e) => { 
    if (volumeDrag) {
        updateVolume(e.pageX);
    }
});
var updateVolume = function (x, vol) {
    var volume = document.querySelector('.volume');
    var percentage;
    //if only volume have specificed
    //then direct update volume
    if (vol) {
        percentage = vol * 100;
    } else {
        var rect = volume.getBoundingClientRect();
        var position = x - rect.left + document.body.scrollLeft;

// var rect = el.getBoundingClientRect();

// {
//   top: rect.top + document.body.scrollTop,
//   left: rect.left + document.body.scrollLeft
// }

        percentage = 100 * position / (parseFloat(getComputedStyle(volume, null).width.replace("px", "")));

    }

    if (percentage > 100) {
        percentage = 100;
    }
    if (percentage < 0) {
        percentage = 0;
    }

    //update volume bar and video volume
    var volume_bar = document.querySelector('.volumeBar');
    volume.style.clip = 'rect(0px, '+percentage+'px, 50px, 0px)';

    volume_bar.style.width = percentage + '%';
    myAudio.volume = percentage / 100;

};





((path === 'player') || (path === 'player/') ) ? ( console.log("PLAYA PLAYA")) : ( console.log ("WCALE NIE") ); 


// function getChromeVersion() { 
//     var raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
//     return raw ? parseInt(raw[2], 10) : false;
// }


		return (
				<div className="heading-player-area">

						<div className="logo-area">
								<HeaderBanner />
								<div className="radio-playlist">
										<AudioPlayButton path={pageUrlClassName} state={((path === 'player') || (path === 'player/')) ? ({isPlayed: true}) : ({isPlayed: false}) } />
										<ReactAudioPlayer
										  src={stream_url}
										  autoPlay={((path === 'player') || (path === 'player/')) ? true : false}
										  preload="none"
										  controls
										  title="Radio Kampus 97,1 FM"
										  volume={((myAudio !== null) ? (myAudio.volume) : (0.9))}
										/>


{/*<div className="playlist-area"><div className="played-now"><div className="played-title"><span>TERAZ:</span></div><div className="played-song"><span className="rotation-song"><a href="https://www.google.com/search?q=Natalia%20Nykiel%20-%20Volcano" target="_blank" rel="noopener noreferrer"><span className="artist">Natalia Nykiel</span> - <span className="song-title">Volcano</span></a></span></div></div><div className="played-before"><div className="played-title"><span>BYŁO:</span></div> <div className="played-song"><span className="rotation-song"><a href="https://www.google.com/search?q=Tame%20Impala%20-%20Lost%20in%20Yesterday" target="_blank" rel="noopener noreferrer"><span className="artist">Tame Impala</span> - <span className="song-title">Lost in Yesterday</span> </a> </span></div></div><div className="played-audition"><div className="played-title"><span>AUDYCJA:</span></div><div className="played-song"><span className="rotation-song"><a href="/programy/tematyczne/normalnie-o-tej-porze/"><span className="schedule--title">Normalnie o tej porze</span></a></span></div><div className="audition-playlist-url"><a href="/playlista/">PLAYLISTA</a></div></div></div>*/}




										<div className="playlist-area">
												<PlayerTopSongs />
												<PlayerCurrentProgram />
										</div>			

								</div>
								<img className="home-logo-2" src={LogoKampus2} alt="Radio Kampus" />
						</div>
						
				</div>
		)
}

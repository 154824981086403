import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';


function noop() {}

if (process.env.NODE_ENV !== 'development') {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
}
 
 
const advancedMatching = {  }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
const options = {
    autoConfig: true, 	// set pixel's autoConfig
    debug: true, 		// enable logs
};


if (process.env.NODE_ENV !== 'development') { 	
	ReactGA.initialize('UA-20301052-1');
	// ReactGA.initialize('UA-20301052-1', { debug: true }) // DEBUG MODE


	ReactPixel.init('221685064839744', advancedMatching, options);
	ReactPixel.pageView();
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  useParams
} from "react-router-dom";
import NewsListElement from '.././Components/NewsListElement';
import NewsSingle from './NewsSingle';
import Breadcrumbs from '.././Components/Breadcrumbs';

function NewsList() {
    let match = useRouteMatch();
    document.title = "Aktualności - Radio Kampus 97,1FM #SAMESZTOSY";

    return (
    <div className="news-section">
      <Breadcrumbs />
      <div className="dynamic-boxes">
        <div className="box-1"> </div>
        <div className="box-2"> </div>
        <div className="box-3"> </div>
      </div>
      <h2 className="">Aktualności</h2>
             
          <div className="news-list-container">

           <Switch>

            <Route path="/aktualnosci/page:newsCategory?">
                <NewsListElementContainer />                       
            </Route>

            <Route path={`${match.path}/:newsName`}>
                <NewsSingle />                 
            </Route>

            <Route path="/aktualnosci">
                <NewsListElementContainer />
            </Route>



       </Switch>

         </div>
      </div>
    );
  }

function NewsListElementContainer() {
  let { newsCategory } = useParams();
  return (
    <div><NewsListElement path={newsCategory} /></div>
    )
}

export default NewsList;
import React from 'react';
import { useAPI } from "./../Service/HomeDataContext";
import {
  NavLink,
} from "react-router-dom";


export default function MenuTopInside({ open, setOpen }) {
  const { homedata } = useAPI();

  return (

<MenuTopInsideElement apidata={homedata} open={!open} setOpen={setOpen} />
    );
  }


class MenuTopInsideElement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    value: '', 
    active: false, 
    menuData: [],
    errors: null};
    this.handleClick = this.handleClick.bind(this);
  }

    handleClick(e) {
      this.props.setOpen(false);
        const bodyLocker = document.querySelector('body,html');
        bodyLocker.classList.toggle('locked');
        console.log('Kliknięto w link.');
    }

  render() {
  let homedata = this.props.apidata;
  let menuTopData = [];
  if (homedata[0] !== undefined ) {
      menuTopData = homedata[0].menu_top_inside;
    };
     return (

      <ul>
                {
                  (menuTopData) ? ( menuTopData.map(menu => {

                    const { id, title, url } = menu;
                    return (
                        <li key={id}><NavLink to={url} onClick={this.handleClick}>{title}</NavLink></li>
                    );
                  }) ) : ( <span /> )
                }
      </ul>

    );
  }
}
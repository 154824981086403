import React from "react";
import {
  Link
} from "react-router-dom";

function NotFound() {
  // let location = useLocation();
  document.title = "Nie znaleziono strony - Radio Kampus 97,1FM #SAMESZTOSY";

  return (
    <div className="not-found-section">
      <h1>Błąd 404</h1>
      <h3>
        Nie znaleziono strony. 

      </h3>
      <Link to="/">Powrót do strony głównej</Link>
    </div>
  );
}


export default NotFound;
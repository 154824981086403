import React from "react";
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
} from "react-router-dom";
import InfoPageElementContainer from '.././Components/InfoPageElement';
import Breadcrumbs from '.././Components/Breadcrumbs';

function InfoPage() {
  let match = useRouteMatch();





    return (
        <div className="info-section">

          <Breadcrumbs />
                <div className="content-header with-filter">
					<h2 className="subpage-title">Informacje</h2>
				</div>     
        <div className="info-background"> 
				<div className="dynamic-boxes">
					<div className="box-1"> </div>
					<div className="box-2"> </div>
					<div className="box-3"> </div>
				</div>   
				<div className="dynamic-boxes bottom-right">
					<div className="box-1"> </div>
					<div className="box-2"> </div>
					<div className="box-3"> </div>
				</div>  				     
        </div>
          <div className="info-container">

           <Switch>


             <Route path={`${match.path}/:infoPageId/:infoPageId`}>
             <InfoPageElementContainer />                      
            </Route>


             <Route path={`${match.path}/:infoPageId`}>
             <InfoPageElementContainer />                      
            </Route>



          <Route path="/informacje">
           <Redirect to={`${match.path}/o-radiu`} />                      
            </Route>

		   </Switch>

         </div>
       </div>
    );
  }

export default InfoPage;
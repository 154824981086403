import React from "react";
import {
  Link
} from "react-router-dom";
import Pagination from './Pagination';
import axios from 'axios';
import {apiUrl} from './../Config/ApiSettings';

class NewsListElement extends React.Component {

    state = {
      newslist: [],
      isLoading: true,
      errors: null,
    };

    getPosts() {
        let page_number;
        let page_category_query;
        let page_base_url = 'aktualnosci';



        // PAGE CATEGORY RULES
            console.log("TYP STRONY: " + page_base_url);
            let page_category_url_split = window.location.pathname.split("/");
                // console.log(page_category_url_split[2]);
            let page_category_url_pop = page_category_url_split.pop();
                // console.log(page_category_url_pop);
                // let page_category_url_start = window.location.pathname.split("/").pop();
            if( !page_category_url_pop ) {
                page_category_url_pop = page_category_url_split.pop();
            }

            var lastChar = window.location.pathname.substr(-1); // Selects the last character
                if (lastChar !== '/') {         // If the last character is not a slash

                }

            if ((window.location.pathname === '/'+page_base_url+'') || (window.location.pathname === '/'+page_base_url+'/')) {
                // console.log("TAK");
                page_category_query = '';
            } else if (document.location.pathname.indexOf('/'+page_base_url+'/page') === 0) {
                page_category_query = '';
            } else {
                // console.log("NIE");
                let page_category_url;
                if (window.location.pathname.indexOf('/page') >= 0) {
                    page_category_url = window.location.pathname.split("/")[2];
                } else {
                    page_category_url = page_category_url_pop.replace(/\/$/, '');
                }
                page_category_query = page_category_url;
                console.log("KATEGORIA:" + page_category_url);
            }

            // Check page URL
            if( window.location.pathname.indexOf('/page') >= 0) {
                page_number = window.location.pathname.split("page")[1];
                // console.log("Wybrana strona: " + page_number);
            } else {
                page_number = '1';
                // console.log("Strona poczatkowa")
            }
        // END PAGE CATEGORY RULES



        axios
            .get(`${apiUrl}/${page_base_url}/${page_number==='1'?'':'page'+page_number}?api=true&parent=${page_category_query}`, {
                headers: {
                    // 'Content-Type': 'application/json'
                },

            })
            // Once we get a response and store data, let's change the loading state
            .then(response => {
                this.setState({
                    newslist: response.data,
                    isLoading: false
                });
            })
            // If we catch any errors connecting, let's update accordingly
            .catch(error => this.setState({ error, isLoading: false }));
    }

    componentDidMount() {
        this.getPosts();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.path !== this.props.path) {

            this.setState({
                isLoading: true,
            });
            this.getPosts();

        }
    }

    render() {
        let page_number;
        const { isLoading, newslist } = this.state;

        let page_path = window.location.pathname.split('/page')[0];
            // console.log(page_path);
            // DUPLICATED PAGE NUMBER
        if( window.location.pathname.indexOf('/page') >= 0) {
            page_number = window.location.pathname.split('/page')[1];
            console.log("Wybrana strona: " + page_number);
        } else {
            page_number = '1';
            // console.log("Strona poczatkowa")
        }
            // END DUPLICATION

        if (newslist !== null) {
            if (newslist[0] !== undefined ) {
                //    PAGINATION
                //    console.log(podcasts);
                //    console.log(podcasts[0].pages_total)
                let number_of_pages = newslist[0].pages_total;

                // ON KAMPUS DEMAND - LIMITED PAGE TO 10 IN NEWS, DISABLING RETURNS ALL FROM NEWS DB
                        number_of_pages = 10;
                const page_numbers = [];




                ////////////////////////////////////////////////////////////////////////////////
                Pagination( number_of_pages, page_numbers, page_number, page_path );
                ////////////////////////////////////////////////////////////////////////////////




                return (
                    <React.Fragment>
                        <div className="news-list">
                        {!isLoading ? (
                            (newslist !== null) ? ( (newslist.map(news => {

                              const { id, title, news_image, url, news_date_added, news_short_description } = news;
                              let news_date = new Date(news_date_added).toLocaleDateString('pl-PL', {
                                  weekday: 'long',
                                  month: 'numeric',
                                  day: 'numeric',
                                  year: 'numeric',
                              })
                              return (
                                  <div className="news-item" key={id}>
                                      <Link to={url}><img className="news--image" src={news_image} alt="" /></Link>
                                      <h3 className="news--title"><Link to={url}>{title}</Link></h3>
                                      <div className="news-metadata">
                                          <span className="news--datetime">{news_date}</span>
                                          <div className="news--description">
                                              <p>{news_short_description}</p>
                                          </div>
                                      </div>
                                  </div>
                                );

                            })) ) : (<p>Nie znaleziono aktualności.</p>)
                        ) : (
                            <p>Wczytywanie...</p>
                        )}
                        </div>
                        <ul className="pagination">
                            {page_numbers}
                        </ul>
                    </React.Fragment>
                );

            } else {
                return (
                    <React.Fragment>
                        <div className="news-list">
                        {!isLoading ? (
                            (newslist !== null) ? ( (newslist.map(news => {

                                const { id, title, news_image, url, news_date_added, news_short_description } = news;
                                let news_date = new Date(news_date_added).toLocaleDateString('pl-PL', {
                                    weekday: 'long',
                                    month: 'numeric',
                                    day: 'numeric',
                                    year: 'numeric',
                                })

                              return (
                                  <div className="news-item" key={id}>
                                      <Link to={url}><img className="news--image" src={news_image} alt="" /></Link>
                                      <h3 className="news--title"><Link to={url}>{title}</Link></h3>
                                      <div className="news-metadata">
                                          <span className="news--datetime">{news_date}</span>
                                          <div className="news--description">
                                              <p>{news_short_description}</p>
                                          </div>
                                      </div>
                                  </div>
                                );

                            })) ) : (<p>Nie znaleziono aktualności.</p>)
                        ) : (
                            <p>Wczytywanie...</p>
                        )}
                        </div>
                    </React.Fragment>
                );
            }
        } else {
            return <p>Nie znaleziono aktualności.</p>
        }
    }
}

export default NewsListElement;
import React from 'react';
import { HomeAPIContextProvider } from "./Service/HomeDataContext";
import AppRoutes from './AppRoutes';

import './sanitize.css';
// import './App.css';
import './fonts.css';
import './style.css';
import './style-mobile.css';





export default function App() {




  return (
        <HomeAPIContextProvider>
            <AppRoutes />
        </HomeAPIContextProvider>

  );
}




import React from "react";
import {
  NavLink,
  useParams
} from "react-router-dom";
import axios from 'axios';
import {apiUrl} from './../Config/ApiSettings';


function InfoPageElementContainer() {
  let { infoPageId } = useParams();
  return (
    <div>
<InfoPageElement path={infoPageId} /></div>
    )
}


class InfoPageElement extends React.Component {
  // State will apply to the posts object which is set to loading by default
  state = {
    pages: [],
    pageslist: [],
    isLoading: true,
    errors: null,
  };





  // Now we're going to make a request for data using axios
  getPosts() {

    let schedule_query_done = this.props.path;


    console.log("test zmiennej INFO: " + schedule_query_done)

      let page_category_query;   
    if (schedule_query_done) {

         page_category_query = schedule_query_done;

        }

      else {
    
        page_category_query = 'o-radiu';
      }

  
    axios
      // This is where the data is hosted
      .get(`${apiUrl}/informacje/${page_category_query}/?api=true`, {
    headers: {
        // 'Content-Type': 'application/json'
    },

    }) 
 
      // Once we get a response and store data, let's change the loading state
      .then(response => {
        this.setState({
          pages: response.data,
          pageslist: response.data[0].siblings,
          isLoading: false
        });
                      
      })
      // If we catch any errors connecting, let's update accordingly
            .catch(error => {
                console.log(error);
                     window.location.href = '/not-found';

                this.setState({ error, isLoading: false } )
            });
  }
  // Let's our app know we're ready to render the data
  componentDidMount() {

  const { isLoading } = this.state;

  if (isLoading) {
    this.getPosts();
  }

    


            let scrollPos = 0;
            const nav = document.querySelector('.info-section .dynamic-boxes');
            const nav2 = document.querySelector('.info-section .dynamic-boxes.bottom-right');

            function checkPosition() {
              let windowY = window.scrollY;
              if (windowY < scrollPos) {
                // Scrolling UP
                nav.classList.add('is-visible');
                nav.classList.remove('is-hidden');
                nav2.classList.add('is-visible');
                nav2.classList.remove('is-hidden');
              } else {
                // Scrolling DOWN
                nav.classList.add('is-visible');
                nav.classList.remove('is-hidden');
                nav2.classList.add('is-visible');
                nav2.classList.remove('is-hidden');
              }
              scrollPos = windowY;
            }



            function debounce(func, wait = 10, immediate = true) {
              let timeout;
              return function() {
                let context = this, args = arguments;
                let later = function() {
                  timeout = null;
                  if (!immediate) func.apply(context, args);
                };
                let callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) func.apply(context, args);
              };
            };

            window.addEventListener('scroll', debounce(checkPosition))




            var isScrolling;

            // Listen for scroll events
            window.addEventListener('scroll', function ( event ) {
              // var startScrolling = setTimeout(function() {
                // Run the callback
                // console.log( 'Scrolling has started.' );
              // }, 1000);

              // Clear our timeout throughout the scroll
              window.clearTimeout( isScrolling );

              // Set a timeout to run after scrolling ends
              isScrolling = setTimeout(function() {
                nav.classList.remove('is-visible');
                nav2.classList.remove('is-visible');
                // Run the callback
                // console.log( 'Scrolling has stopped.' );

              }, 66);

            }, false);


  }


componentDidUpdate(prevProps, prevState) {
  // only update chart if the data has changed
  if (prevProps.path !== this.props.path) {

     this.setState({
       isLoading: true
     })

    this.getPosts();
  }
}


  // Putting that data to use
  render() {
    const { isLoading, pages, pageslist } = this.state;

    function navigationTrigger() {
          let selectCategoryText = document.querySelector('.navigation-filter .select-category');
          let navTarget = document.querySelector('.navigation-filter');
          navTarget.classList.toggle('active');
          selectCategoryText.classList.toggle('hidden'); 
    }

    function selectCategory() {
          let selectCategoryText = document.querySelector('.navigation-filter .select-category');     
          selectCategoryText.classList.remove('active');      
    }

    return (
      <React.Fragment>

          {!isLoading ? (
            pages.map(page => {
              const { id, title, info_page_content } = page;
              document.title = title + " - Radio Kampus 97,1FM #SAMESZTOSY";  
              return (
            <div key={id} className="info-single-container">
                    <h3 className="subpage-title">{title}</h3>
          <ul className="navigation-filter" onClick={navigationTrigger}><span className="dropdown-button"></span> <span className="select-category active">Wybierz</span>

          {!isLoading ? (
              pageslist.map(pagelist => { 
                    const { id, title, url } = pagelist;                   
              return (
                <li key={id}><NavLink onClick={selectCategory} to={url}>{title}</NavLink></li>
              );
                          })
          ) : (
            <p></p>
          )}
          </ul>




                
                  <div className="info-description">

                    <div dangerouslySetInnerHTML={ { __html: info_page_content }} />
                  </div>
                
              </div>
              );
            })
          ) : (
            <p>Wczytywanie...</p>
          )}
      </React.Fragment>
    );
  }
}

export default InfoPageElementContainer;
import React from "react";
import { useAPI } from "./../Service/HomeDataContext";

export default function SocialMenu() {
  const { homedata } = useAPI();
  // console.log(homedata);


  let socialData = [];
  if (homedata[0] !== undefined ) {
      socialData = homedata[0].socials;
//      console.log(homedata[0].socials);
    };

  return (

  <ul className="social-menu">
      {
          (socialData) ? ( socialData.map(portal => {
              const { id, title, name, url } = portal;
              return (
          <li key={id}><a href={url} target="_blank" rel="noopener noreferrer"><i className={"icon-social-" + name} title={title} aria-label={title}></i></a></li>
              );
          }) ) : ( <span /> )
      }
  </ul>


  );
}
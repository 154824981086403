import React from "react";
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams
} from "react-router-dom";
import ScrollToTop from '.././Components/ScrollToTop';

// import { PodcastsAPIContextProvider } from ".././Service/PodcastsDataContext";
import PodcastListFilteredElement from '.././Components/PodcastListFilteredElement';
import ProgramPodcastSingle from './ProgramPodcastSingle';
import PodcastListPrograms from '.././Components/PodcastListPrograms';
import PodcastListProgramsResults from '.././Components/PodcastListProgramsResults';
import PodcastSingleFiltered from './PodcastSingleFiltered';
import BreadcrumbsPodcasts from '.././Components/BreadcrumbsPodcasts';
import PodcastListSearchBar from "../Components/PodcastListSearchBar";
// import SearchPage from "./Search";
// podcasts update 2024
function PodcastListFiltered() {



    let match = useRouteMatch();
    document.title = "Podcasty/Video - Radio Kampus 97,1FM #SAMESZTOSY";
    return (
        // <PodcastsAPIContextProvider>

    <div className="podcasts-section">


        <BreadcrumbsPodcasts />
        <div className="dynamic-boxes">
            <div className="box-1"> </div>
            <div className="box-2"> </div>
            <div className="box-3"> </div>
        </div>
        <div className="content-header with-filter">
            <h2>Podcasty</h2>

            {/* <PodcastListCategories /> */}
        </div>
        <div className="podcast-list-container">

            <Switch>

                <Route path="/podcasty/:podcastCategory/page:podcastCategory?">
                    <ProgramPodcastSingleContainer />
                    <PodcastListElementContainer />
                </Route>

                <Route path="/podcasty/szukaj/:searchQuery/page:pageQuery">
                    <div className="search-bar"><PodcastListSearchBarContainer  /></div>
                    <PodcastListProgramsResultsContainer />
                    <PodcastListResultsElementContainerPaged />
                </Route>

                <Route path="/podcasty/szukaj/:searchQuery">
                    <div className="search-bar"><PodcastListSearchBarContainer  /></div>
                    <PodcastListProgramsResultsContainer />
                    <PodcastListResultsElementContainer />
                </Route>

                <Route path="/podcasty/szukaj/">
                    <Redirect to={"/podcasty"} />
                </Route>

                <Route path="/podcasty/sluchaj/:podcastName">
                    <ScrollToTop />
                    <PodcastSingleFiltered />
                </Route>

                <Route path="/podcasty/sluchaj/">
                    <Redirect to={"/podcasty"} />
                </Route>

                <Route path="/podcasty/page:podcastCategory?">
                    <ProgramPodcastSingleContainer />
                    <PodcastListElementContainer />
                </Route>

                <Route path={`${match.path}/:podcastCategory`}>
                    <ScrollToTop />
                    <ProgramPodcastSingleContainer />
                    <PodcastListElementContainer />
                </Route>



                <Route path="/podcasty">
                    <ScrollToTop />
                    <div className="search-bar"><PodcastListSearchBar  /></div>
                    <PodcastListPrograms />
                </Route>



            </Switch>

        </div>
    </div>
    // {/* </PodcastsAPIContextProvider> */}
    );

}

function ProgramPodcastSingleContainer() {
    // let { podcastCategory } = useParams();
    return (
      <div><ProgramPodcastSingle /></div>
    )
}

function PodcastListElementContainer() {

    let { podcastCategory } = useParams();
    return (
        <div><PodcastListFilteredElement path={ podcastCategory } /></div>
    )

}

function PodcastListResultsElementContainer() {

    let { searchQuery } = useParams();
    return (
        <div><h3>Odcinki</h3><PodcastListFilteredElement path={ searchQuery } keywords={ true } /></div>
    )

}

function PodcastListResultsElementContainerPaged() {

    let { pageQuery } = useParams();
    return (
        <div><h3>Odcinki</h3><PodcastListFilteredElement path={ pageQuery } keywords={ true } /></div>
    )

}

function PodcastListProgramsResultsContainer() {
    let { searchQuery } = useParams();
    return (
        <div><h3>Podcasty</h3><PodcastListProgramsResults path={ searchQuery } /></div>
    )
}


function PodcastListSearchBarContainer() {

    let { searchQuery } = useParams();
    return (
        <PodcastListSearchBar path={ searchQuery } />
    )

}

export default PodcastListFiltered;
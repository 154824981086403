import React, { useState } from "react";
import {
    Link
  } from "react-router-dom";

function ModalElement(props) {
    const [isActive, setActive] = useState(false);

    const toggleClass = () => {
      setActive(!isActive);
    };

    return (
    <div aria-hidden="true" className={'modal ' + (isActive ? 'hidden' : null)} id="modal-one">
        <div className="modal-dialog">
            <div className="modal-header">
                <h2>{props.title}</h2>
            </div>

            <div className="modal-body">
                <p dangerouslySetInnerHTML={ { __html: props.text }} />
            </div>
            <div className="modal-footer"><button className="btn-modal accept" onClick={toggleClass}>{props.textAccept}</button></div>
            <div className="modal-footer"><Link className="btn-modal decline" to="/">{props.textDecline}</Link></div>
        </div>
    </div>
     );
  }

export default ModalElement;